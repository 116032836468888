import React, { useState, useEffect } from "react";
import Logo from "../../images/logo.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Navigation } from "../../api/api.js";
import { Button } from "../utils/Components.js";
import * as Icons from '@tabler/icons-react';
import { Link as ScrollLink } from "react-scroll";

const Header = () => {
  const location = useLocation().pathname;
  const [toggle, setToggle] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const sectionOffset = -20;

  return (
    <>
      <header className={`header ${toggle ? "active" : ''} ${isScrolled ? "scrolled" : ''}`}>
        <div className="container">
          <div className="header_main">
            <Link to="/" className="logo">
              <img src={Logo} alt="Logo"/>
            </Link>
            <ul className={`navigation ${toggle ? "active" : ''}`}>
              <Button className="close show_992" onClick={() => setToggle(!toggle)}>
                <Icons.IconX/>
              </Button>
              {Navigation.map(function (nav, key) {
                return (
                  <li key={key} onClick={() => setToggle(!toggle)}>
                    <NavLink className="nav_link" to={nav.link}>
                      {nav.name}
                    </NavLink>
                    {nav.submenu && (
                      <ul className="sub_menu">
                        {nav.submenu.map((subNav, subkey) => (
                          <li key={subkey}>

                            {
                              location === "/about" || location === "/our-expertise" ? (
                                <ScrollLink 
                                  spy={true}
                                  to={subNav.link}
                                  offset={sectionOffset}
                                  hashSpy={true}
                                  smooth={true}
                                  duration={800}
                                  className="subNav_link"
                                >
                                  <span className="relative">{subNav.name}</span>
                                </ScrollLink>
                              ) : (
                                <Link to={`${nav.link}#${subNav.link}`} className="subNav_link">
                                  <span>{subNav.name}</span>
                                </Link>
                              )
                            }
                            
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              })}
              <li>
                <Button className="header_btn show_992">Get A Free Quote</Button>
              </li>
            </ul>
            <Button className="header_btn show_992" onClick={() => setToggle(!toggle)}>
              <Icons.IconMenu2/>
            </Button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
