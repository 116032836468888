import React, { useRef, useEffect } from "react";
import MainSec from "../utils/MainSec.js";
import * as Icons from "@tabler/icons-react";
const Security = () => {

  const serviceData = [
    {
      title: "Proactive Management",
      description: "Preventing issues before they affect you.",
    },
    {
      title: "Focus on Customer",
      description: "Satisfaction, not just technology.",
    },
    {
      title: "Highly Trained Staff",
      description: "Not just in technology, but also in customer services.",
    },
    {
      title: "Flexible Service Offering",
      description: "Tailored exactly to your business needs.",
    },
    {
      title: "Competitively Priced",
      description:
        "With a complete report demonstrating where and how we deliver value.",
    },
    {
      title: "Understanding of Business Model",
      description:
        "We monitor / constantly support your mission-critical services so there are no IT-related business interruptions.",
    },
  ];
  const mediumBusinesses = [
    {
      title: "Customer-Centricity",
      description:
        "We place our clients' objectives at the heart of our operations, ensuring every solution we provide resonates with their core goals.",
    },
    {
      title: "Collaboration",
      description:
        "We believe in the power of unity. By seamlessly integrating into our client's teams, we foster an environment of mutual growth and success.",
    },
    {
      title: "Innovation",
      description:
        "Our quest for excellence is driven by a thirst for innovation. We are constantly exploring novel ways to provide optimized solutions to our clients.",
    },
    {
      title: "Integrity",
      description:
        "Every step we take is grounded in honesty and transparency, ensuring that our clients always have full visibility and control over the projects we undertake together.",
    },
    {
      title: "Efficiency",
      description:
        "Our commitment to reducing costs and streamlining processes is unwavering. We utilize the best of technology and methodologies to ensure maximum productivity.",
    },
  ];



  const clockworkTechnologies = {
    expertise: [
        {
            title: "Systems Engineering",
            list: [
                "Expertise in Active Directory, VMware, comprehensive storage integration and management, and Office 365, ensuring optimized infrastructure utilization and accessibility.",
                "Design, implementation, and maintenance of intricate IT systems, tailored to client-specific needs.",
                "Proficiency in both traditional architectures and cutting-edge solutions.",
            ]
        },
        {
            title: "Cloud Services",
            list: [
                "Mastery in migration and management across all major cloud platforms: AWS, Azure, and Google Cloud.",
                "Cloud strategy, deployment, and optimization for businesses of all scales."
            ]
        },
        {
            title: "Programming & Development",
            list: [
                "Specialized in web application programming and custom solutions for evolving and migrating legacy systems.",
                "Expertise in programming, designing, and optimizing Salesforce environments.",
                "Use of modern programming languages, frameworks, and agile development methodologies for swift project delivery."
            ]
        },
        {
            title: "Network Engineering",
            list: [
                "Design and deployment of intricate LAN, WAN, and Wifi infrastructures, capable of supporting thousands of locations, including multiple disaster recovery sites using the latest technologies.",
                "Certified engineers adept at supporting diverse networking environments from LAN/WAN to Wifi.",
                "A unique approach that involves comprehensive environmental analysis, enabling us to offer multiple, cost-effective solutions tailored to each customer's needs."
            ]
        },
        {
            title: "Administration",
            list: [
                "Comprehensive administrative capabilities, covering VMware, servers, change management, and program management.",
                "A team of certified engineers with vast experiential knowledge and recognized qualifications in these domains."
            ]
        },
        {
            title: "Assessment and Research",
            list: [
                "Services to enhance project performances, identify the most suitable modernization paths, and optimize procurement processes.",
                "Comprehensive evaluations for system and network enhancements, along with white paper generation for significant projects, all aimed at saving customer time and expenses."
            ]
        },
        {
            title: "Consulting",
            list: [
                "Strategic consultations ensuring the best fit for project scopes, significantly reducing time inefficiencies.",
                "A commitment to delivering superior service quality while minimizing associated costs for our clients."
            ]
        }
    ],
    strategy: {
        researchDrivenInsights: "We stay ahead of the curve by tapping into global tech trends, market analyses, and customer feedback. Drawing from industry-leading sources and websites, our strategy is rooted in real-time, relevant data.",
        adaptiveFramework: "Understanding that the IT landscape is perpetually evolving, our strategy framework is flexible and adaptive. This approach allows us to rapidly adjust to industry shifts, ensuring our services are always at the forefront.",
        customerCentricRefinement: "Our strategy prioritizes the customer. Through regular feedback loops and client consultations, we fine-tune our offerings, ensuring they align with the changing needs of our clientele.",
        methodologicalExcellence: "We believe in the power of a structured, systematic approach. By implementing rigorous methodologies, we guarantee that every project we undertake is managed with precision, thoroughness, and an unmatched attention to detail.",
        internalKnowledgeSharing: "Clockwork Technologies thrives on collective intelligence. Our teams consistently share insights, learning from each project to enrich our collective knowledge base. This iterative learning process forms the bedrock of our strategy, fostering innovation from within.",
        benchmarkingAndCompetitiveAnalysis: "We continually benchmark our services against industry leaders, identifying gaps and opportunities for advancement. This comparative perspective ensures that Clockwork Technologies remains a frontrunner in the IT integration and engineering space."
    }
};

  return (
    <>
      <MainSec
        src={require("../../images/security.jpg")}
        heading="security"
      />
      <section>
        <div className="container">
          <div className="exciting_offers_item">
            <div className="exciting_offers_content">
              <h2 className="exciting_offers_heading">
                zero trust
              </h2>
              <div className="exciting_offers_flex">
                <p>
                  Clockwork Technologies empowers enterprises to synchronize with the cutting-edge zero trust principles, functions, and architectures. We believe in harnessing the potential of both current technologies, like advanced firewalls, and the capabilities of the latest cyber solutions. Our roadmap ensures a steadfast journey to a secure zero trust framework. Clockwork Technologies Specializes in: • Zero Trust Network Integration • Advanced SD-WAN Solutions • Robust Internet Gateways • Enterprise Mobility Management (EMM) • Next-Gen SASE/SSE Architectures
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="exciting_offers bg"
      >
        <div className="container">
          <div className="exciting_offers_item">
            <div className="exciting_offers_content">
              <h2 className="exciting_offers_heading">network & device protection</h2>
              <div className="exciting_offers_flex column">
                <p>
                  In this evolving cyber landscape, mastering both the macro and micro nuances of security is paramount. Clockwork Technologies’ holistic approach encapsulates overarching organizational needs while meticulously pinpointing potential vulnerabilities. Clockwork Technologies Delivers: • In-Depth Micro & Macro Segmentation • Comprehensive Asset Overviews • Revolutionary Software-Defined Boundaries • Strategic Network Access Protocols • Potent Firewall Mechanisms
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="exciting_offers"
      >
        <div className="container">
          <div className="exciting_offers_item">
            <div className="exciting_offers_content">
              <h2 className="exciting_offers_heading">IDENTITY & ACCESS AUTHORITY (IAA)</h2>
              <div className="exciting_offers_flex column">
                <p>
                  Safeguarding genuine users and their invaluable data is foundational for a secure digital space. Clockwork Technologies is your beacon, guiding and seamlessly blending optimal identity management strategies. Clockwork Technologies Facilitates: • Streamlined SSO • Multi-Factor Authentication (MFA) • Adaptive Conditional Access • Privileged Access Management (PAM) • Fluid Identity Operations • Intuitive Dynamic Risk Analysis
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="exciting_offers bg"
      >
        <div className="container">
          <div className="exciting_offers_item">
            <div className="exciting_offers_content">
              <h2 className="exciting_offers_heading">user protection strategy</h2>
              <div className="exciting_offers_flex column">
                <p>
                  Harnessing a profound awareness of potential user threats, Clockwork Technologies combines behavioral intelligence with advanced malware and ransomware countermeasures, ensuring a protective shield around every user. Clockwork Technologies Ensures: • Advanced Malware Detection • Proactive Ransomware Counteraction • Behavioral Insights & Analytics • Mobile Device Management (MDM) • Infallible Email Security Layers
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="exciting_offers"
      >
        <div className="container">
          <div className="exciting_offers_item">
            <div className="exciting_offers_content">
              <h2 className="exciting_offers_heading">COMPREHENSIVE THREAT DETECTION & MITIGATION (CTDM)</h2>
              <div className="exciting_offers_flex column">
                <p>
                  Armed with a robust toolkit, Clockwork Technologies provides businesses proactive alerts against both internal and external adversarial intents, ensuring consistent, vigilant oversight. Clockwork Technologies Provides: • Incident Response (IR) Expertise • End-to-end EDR Solutions • Managed Detection and Response (MDR) • Integrated SIEM Systems • Security Operations Center (SOC) Mastery • Continuous Diagnostics & Mitigation (CDM) • Real-time Threat Intelligence
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="exciting_offers bg"
      >
        <div className="container">
          <div className="exciting_offers_item">
            <div className="exciting_offers_content">
              <h2 className="exciting_offers_heading">APPLICATION & OPERATIONAL SECURITY</h2>
              <div className="exciting_offers_flex column">
                <p>
                  In the era of expansive digital ecosystems, Clockwork Technologies understands that security extends to every application and operational facet. We tailor-make protection strategies, considering the unique intricacies of your infrastructure. Clockwork Technologies Provides: • Web Application Firewalls (WAF) • Dedicated Application Safeguards • Cloud Access Security Brokers (CASB) • Containerized Security Solutions
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="exciting_offers"
      >
        <div className="container">
          <div className="exciting_offers_item">
            <div className="exciting_offers_content">
              <h2 className="exciting_offers_heading">POLICY & REGULATORY FRAMEWORKS</h2>
              <div className="exciting_offers_flex column">
                <p>
                  Seamless coordination among internal divisions mandates well-structured policies and procedures. Clockwork Technologies steers your venture through the operational intricacies of policy adherence, adapting and implementing tried-and-true systems to fit your enterprise's unique demands. Clockwork Technologies Provides: • Custom Policy Formulation • Dynamic Policy Management • Consistent Policy Implementation
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Security;
