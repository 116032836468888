import React, { useState } from "react";
import MainSec from "../utils/MainSec.js";
import { Button, Input } from "../utils/Components.js";
import Map from "../utils/Map.js";
const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    phone: "",
  });

  const handleInputChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission logic here
    console.log(formData);
  };

  return (
    <>
      <MainSec
        src={require("../../images/career.jpg")}
        heading="contact us"
      />
      <section className="contact">
        <div className="container">
          <div className="p_s_headings">
            <h2 className="p_s_heading">Get a Free Quote</h2>
            <p className="p_s_description">
              Get a free quote now to enhance your business...
            </p>
          </div>
          <div className="get_qoute_main">
            <form onSubmit={handleSubmit}>
              <div className="form_wrapper">
                <Input
                  value={formData.name}
                  onChange={(value) => handleInputChange("name", value)}
                  label="Name"
                  type="text"
                />
                <Input
                  value={formData.email}
                  onChange={(value) => handleInputChange("email", value)}
                  label="Email"
                  type="email"
                />
                <Input
                  value={formData.company}
                  onChange={(value) => handleInputChange("company", value)}
                  label="Company"
                  type="text"
                />
                <Input
                  value={formData.phone}
                  onChange={(value) => handleInputChange("phone", value)}
                  label="Phone"
                  type="tel"
                />
              </div>
              <Button className="qoute_btn" type="submit">
                Submit
              </Button>
            </form>
            <Map address="6604 Millwood Road, Bethesda, MD, USA" />
          </div>
          <div className="c_s_infos">
            <div className="c_s_info ">
              <h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-location"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5" />
                </svg>
              </h4>
              <div className="c_s_content">
                <span>location</span>
                <p>Clockwork Technologies, LLC 4823 Chichester House Rd Olney, MD 20832</p>
              </div>
            </div>
            <div className="c_s_info">
              <h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-phone-call"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                  <path d="M15 7a2 2 0 0 1 2 2" />
                  <path d="M15 3a6 6 0 0 1 6 6" />
                </svg>
              </h4>
              <div className="c_s_content">
                <span>phone</span>
                <p>240-813-0707</p>
              </div>
            </div>
            <div className="c_s_info ">
              <h4>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-mail-opened"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 9l9 6l9 -6l-9 -6l-9 6" />
                  <path d="M21 9v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10" />
                  <path d="M3 19l6 -6" />
                  <path d="M15 13l6 6" />
                </svg>
              </h4>
              <div className="c_s_content">
                <span>Mail</span>
                <p> info@clockworktechnologies.com</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
