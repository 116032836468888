import React from 'react'

const MainSec = ({src,heading, className}) => {
	return (
		<section className={`top_section first_sec ${className ? className : ""}`}>
			<figure>
				<img src={src} alt=""/>
			</figure>
			<div className="container">
				<h1>{heading}</h1>
			</div>
		</section>
	)
}

export default MainSec