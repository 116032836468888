import HeroSlide1 from "../images/heroslider/hero_1.jpg";
import HeroSlide2 from "../images/heroslider/hero_2.jpg";
import HeroSlide3 from "../images/heroslider/hero_3.jpg";
export const Navigation = [
  {
    name: "About",
    link: "/about",
    submenu: [
      {
        name: "Mission",
        link: "mission",
      },
      {
        name: "Vision",
        link: "vision",
      },
      {
        name: "Strategy",
        link: "strategy",
      },
      {
        name: "Values",
        link: "values",
      },
    ],
  },
  {
    name: "Our Expertise",
    link: "/our-expertise",
    submenu: [
      {
        name: "Systems Engineering",
        link: "systems-engineering",
      },
      {
        name: "Cloud Services",
        link: "cloud-services",
      },
      {
        name: "Programming & Development",
        link: "programming-&-development",
      },
      {
        name: "Network Engineering",
        link: "network-engineering",
      },
      {
        name: "Administration",
        link: "administration",
      },
      {
        name: "Assessment And Research",
        link: "assessment-and-research",
      },
      {
        name: "Consulting",
        link: "consulting",
      },
      {
        name: "Company Strategy",
        link: "company-strategy",
      },
    ],
  },
  {
    name: "Managed Services",
    link: "/managed-services",
  },

  {
    name: "Security",
    link: "/security",
  },
  {
    name: "Career",
    link: "/career",
  },
  {
    name: "Why Clockwork",
    link: "/why-clockwork",
  },
  {
    name: "Technology",
    link: "/technology",
  },
  // {
  //   name: "Client Login",
  //   link: "/client-login",
  // },
  {
    name: "Contact",
    link: "/contact",
  },
];
export const HeroSlider = [
  {
    title: "  Full Support Service",
    description:
      "Full Support Service With 'Full Support Services' we take complete responsibility for our customer's IT requirements; managing day-to-day IT services, providing long term advice regarding IT related business issues and delivering exceptional value.",
    image: HeroSlide1,
    link: "/exciting-offers#fullSupportService",
  },
  {
    title: "Virtualization Services",
    description:
      "All of our trained and certified engineers are dedicated to provide unique virtualization services based on your environment.",
    image: HeroSlide2,
    link: "/exciting-offers#virtualizationServices",
  },
  {
    title: "Hosted Spam Service for Small and Medium Businesses",
    description: "Secure, Efficient and Cost-Effective.",
    image: HeroSlide3,
    link: "/exciting-offers#mediumBusinesses",
  },
];

export const ProactiveServices = [
  "IT Support & Maintenance",
  "24x7 Monitoring",
  "Remote & Onsite Support",
  "Mission Critical Services Monitoring & Support",
  "Quick Response",
  "Backup and Restore (Onsite & Offsite)",
];

export const imageArray = [
  {
    id: 1,
    src: require("../images/companies/companies_1.png"),
    alt: "Company 1",
  },
  {
    id: 2,
    src: require("../images/companies/companies_2.png"),
    alt: "Company 2",
  },
  {
    id: 3,
    src: require("../images/companies/companies_3.png"),
    alt: "Company 3",
  },
  {
    id: 4,
    src: require("../images/companies/companies_4.png"),
    alt: "Company 4",
  },
  {
    id: 5,
    src: require("../images/companies/companies_5.png"),
    alt: "Company 5",
  },
  {
    id: 6,
    src: require("../images/companies/companies_6.png"),
    alt: "Company 6",
  },
  {
    id: 7,
    src: require("../images/companies/companies_7.png"),
    alt: "Company 7",
  },
  {
    id: 8,
    src: require("../images/companies/companies_8.png"),
    alt: "Company 8",
  },
  {
    id: 9,
    src: require("../images/companies/companies_9.png"),
    alt: "Company 9",
  },
  {
    id: 10,
    src: require("../images/companies/companies_10.png"),
    alt: "Company 10",
  },
  {
    id: 11,
    src: require("../images/companies/companies_11.png"),
    alt: "Company 11",
  },
  {
    id: 12,
    src: require("../images/companies/companies_12.png"),
    alt: "Company 12",
  },
  {
    id: 13,
    src: require("../images/companies/companies_13.png"),
    alt: "Company 13",
  },
  {
    id: 14,
    src: require("../images/companies/companies_14.png"),
    alt: "Company 14",
  },
  {
    id: 15,
    src: require("../images/companies/companies_15.png"),
    alt: "Company 15",
  },
  {
    id: 16,
    src: require("../images/companies/companies_16.png"),
    alt: "Company 16",
  },
  {
    id: 17,
    src: require("../images/companies/companies_17.png"),
    alt: "Company 17",
  },
  {
    id: 18,
    src: require("../images/companies/companies_18.png"),
    alt: "Company 18",
  },
];

export const careersData = [
  {
    title: "Sr. Systems Engineer",
    desc: "At Clockwork Technologies, our Sr. Systems Engineers are pivotal in architecting, implementing, and supporting advanced IT infrastructure solutions. Their expertise in platforms such as Active Directory, VMware, storage management, Office 365, and major cloud platforms ensures that our clients are equipped with a sophisticated and harmonized IT environment.",
    responsibilities: [
      "Design, implement, and support infrastructure solutions and services including Active Directory, VMware, Office 365, and storage management.",
      "Plan, design, and manage cloud solutions across prominent cloud providers including AWS, Azure, and Google Cloud.",
      "Collaborate with technical and non-technical stakeholders to gather system requirements and suggest integrative solutions.",
      "Continuously monitor and optimize system and cloud performance, ensuring scalability and reliability.",
      "Experience in managing large and enterprise-class data centers with 500+ servers.",
      "Liaise with vendors and cloud service providers for the procurement and management of system resources.",
    ],
    requirements: [
      "Bachelor's degree in Computer Science, IT, or a related field.",
      "Proficiency with Active Directory, VMware, Office 365, and storage management tools.",
      "Experience with major cloud platforms like AWS, Azure, and Google Cloud.",
      "Strong analytical and troubleshooting skills.",
      "Ability to obtain TS clearance.",
    ],
    desc2:
      "We're continually on the lookout for talented professionals to fill the roles outlined below. If you possess the requisite skills, we invite you to reach out to us at info@clockworktechnologies.com. While we may not have immediate openings, rest assured that your information will be securely stored in our talent database. As soon as a suitable position becomes available, we'll get in touch with you.",
  },

  {
    title: "Sr. Network Engineer",
    desc: "Sr. Network Engineers at Clockwork Technologies are entrusted with the design, implementation, and management of our vast networking landscape. Their proficiency in designing complex LAN/WAN & Wifi environments ensures optimal network integrity and performance.",
    responsibilities: [
      "Design and implement intricate LAN/WAN & Wifi environments.",
      "Experience building and managing enterprise-class network environments with SD-WAN and 100+ internal LANs.",
      "Lead disaster recovery strategies and oversee their execution.",
      "Thoroughly analyze customer environments and propose custom solutions.",
      "Experience in managing large and enterprise-class data centers with 500+ servers.",
      "Ensure the delivery of cost-effective, efficient, and reliable network solutions for clients.",
    ],
    requirements: [
      "Bachelor's degree in Networking, IT, or a related field.",
      "Expertise in designing complex network architectures.",
      "CCIE certification is preferred.",
      "Relevant experience in managing extensive networking environments.",
      "Ability to obtain TS clearance.",
    ],
  },
  {
    title: "Sr. Technical Project Manager",
    desc: "Sr. Technical Project Managers at Clockwork Technologies are the spearheads that guarantee the meticulous execution of our technical projects. Their extensive knowledge across systems, network, security, and development ensures the synchronized coordination of diverse technical teams.",
    responsibilities: [
      "Lead and govern sophisticated technical projects from initiation to fruition.",
      "Engage with stakeholders to clarify project scope, aims, and deliverables.",
      "Monitor project progression and guarantee punctual delivery.",
      "Collaborate with diverse technical teams, ensuring milestones are met.",
      "Possess a comprehensive understanding of all technical fields, including systems, network, security, and development.",
    ],
    requirements: [
      "Bachelor's degree in IT, Management, or a related field.",
      "Solid experience in overseeing technical projects.",
      "Exceptional leadership and communication abilities.",
      "Ability to obtain TS clearance.",
    ],
  },
];
