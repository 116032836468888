import React from "react";
import MainSec from "../utils/MainSec.js";
import * as Icons from "@tabler/icons-react";

const ApplicationSupport = () => {
  const services = [
    "Windows Server Migration & Implementation",
    "Active Directory Design, Migration, & Implementation",
    "SQL & Exchange Server Design, Integration, & Clustering",
    "Oracle Design, Implementation, and Support",
    "Email Archiving & Retention / Spam Filtering",
    "Group Policy Assessment, Design, & Implementation",
    "Windows Infrastructure Design, Deployment, & Support",
    "Citrix Assessment, Implementation, and Support",
    "Terminal Server Implementation and Support with Load Balancing",
  ];
  return (
    <>
      <MainSec
        src={require("../../images/career.jpg")}
        heading="Applications Support"
      />
      <section className="app_services">
        <div className="container">
          <div className="app_services_main">
            <div className="app_service_item">
              <p className="clockwork_text">
                There are technologies that exist in almost any network
                infrastructure, and Clockwork Technologies continuously seeks to
                understand and test these emerging technologies and products to
                best know how they can be integrated and benefit our clients.
                Here are a few of the technologies / solutions we support and
                provide services for.
              </p>
            </div>
            <div className="app_service_item">
              <ul className="mng_list">
                {services.map((service, index) => (
                  <li key={index}>
                    <span>{index + 1}</span>
                    <p>{service}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="app_service_item">
              <p className="clockwork_text">
                Clockwork Technologies has a wide variety of expertise that can
                also help in your current business process improvement by
                assessing your current environment.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ApplicationSupport;
