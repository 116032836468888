import React from "react";
import MainSec from "../utils/MainSec.js";
import * as Icons from "@tabler/icons-react";

const DataCenterGrooming = () => {
  const questions = [
    "Is your Data Center just a web of cables and servers with no labels?",
    "Do you have Servers that you don't know if they are under warranty or not?",
    "Do you have old servers that you don't know the purpose of?",
    "Do you have old equipment mounted in the racks that you just don't have time to take out?",
    "Unable to locate the Internet and DMZ cables?",
    "You don't know what equipment is critical and which isn't?",
    "Your data center shouldn't be a place where you are scared to go because you don't know what cables are going where and what equipment is doing what.",
  ];

  return (
    <>
      <MainSec
        src={require("../../images/career.jpg")}
        heading="Data Center Grooming"
      />
      <section className="data_center_services">
        <div className="container">
          <div className="data_center_services_main">
            <p className="clockwork_text">
              These special services are designed by Clockwork Technologies to
              do TECH-CLEANUP in the data centers. Here are a few of the
              questions you will want to ask yourself to see if you can take
              advantage of these services.
            </p>

            <ul className="list">
              {questions.map((question, index) => (
                <li key={index}>
                  <Icons.IconPoint />
                  {question}
                </li>
              ))}
            </ul>
            <p className="clockwork_text">
              Your data center shouldn't be a place where you are scared to go
              because you don't know what cables are going where and what
              equipment is doing what.
              <br />
              <br />
              Clockwork Technologies' specialized technicians can consolidate
              your Data Center, Organize it and make it presentable to higher
              management.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default DataCenterGrooming;
