import React from 'react'
import { imageArray } from '../../api/api.js';
import MainSec from "../utils/MainSec.js";

const Technology = () => {
	return (
		<>
		 <MainSec src={require("../../images/technology.jpg")} heading="technology" />
			<section className="companies technology">
	        <div className="container">
	        	<div className="section_heading sub_section">
	        		<h3>We work / sell / deploy and integrate the following solutions into customer environments based on their unique needs.</h3>
	        	</div>
	          <div className="companies_main">
	            {imageArray.map((image) => (
	              <figure key={image.id}>
	                <img src={image.src} alt={image.alt} />
	              </figure>
	            ))}
	          </div>
	        </div>
	      </section>
		</>
	)
}

export default Technology