import React from "react";
import MainSec from "../utils/MainSec.js";

const Storage = () => {
  return (
    <>
      <MainSec src={require("../../images/career.jpg")} heading="Storage" />
      <section className="storage">
        <div className="container">
          <div className="storage_main">
            <div className="sub_section">
              <h2 className="sub_heading">Backup and Recovery</h2>
              <p className="clockwork_text">
                Clockwork Technologies' experts can help you build an "All
                Covered" backup and recovery solution for your business'
                critical data. Clockwork Technologies will make sure that you
                are prepared to recover everything possible from your equipment
                and minimize accidental loss of data.
              </p>
            </div>

            <div className="sub_section">
              <h2 className="sub_heading">Business Continuity</h2>
              <p className="clockwork_text">
                Clockwork Technologies will help you keep your business critical
                application running and reduce the amount of downtime during
                project implementations to ensure business continuity. Clockwork
                Technologies will also help you meet your Business Continuity
                SLAs.
              </p>
            </div>

            <div className="sub_section">
              <h2 className="sub_heading">Data Migration and Replication</h2>
              <p className="clockwork_text">
                Clockwork Technologies' experts can help you with data
                migration, replication in case of storage upgrades or
                replacements, relocating, storage consolidations, and
                application availability.
              </p>
            </div>

            <div className="sub_section">
              <h2 className="sub_heading">Data Consolidation</h2>
              <p className="clockwork_text">
                Clockwork Technologies can help you maximize your storage
                utilization, remove stale data, and maximize server storage
                space with our proven Data Consolidation Process.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Storage;
