import React from "react";
import MainSec from "../utils/MainSec.js";
import * as Icons from "@tabler/icons-react";

const VirtualizationServices = () => {
  const services = [
    {
      title: "Assessment Services including ROI Analysis",
      description:
        "Expertise in Active Directory, VMware, comprehensive storage integration and management, and Office 365, ensuring optimized infrastructure utilization and accessibility.",
    },
    {
      title: "Implementation Services",
      description:
        "Design, implementation, and maintenance of intricate IT systems, tailored to client-specific needs.",
    },
    {
      title: "Storage Disaster Recovery",
      description:
        "Proficiency in both traditional architectures and cutting-edge solutions.",
    }
  ];
  return (
    <>
      <MainSec
        src={require("../../images/career.jpg")}
        heading="Virtualization Services"
      />
      <section className="virtualization_services">
        <div className="container">
          <div className="virtualization_services_main">
            <div className="v_s_wrapper">
              <div className="v_s_item">
                <ul className="list">
                  {services.map((service, index) => (
                    <li key={index}>
                      <Icons.IconPoint />
                      {service.description}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <p className="clockwork_text">
              Clockwork's Professional Services Team can also help you with many other unique virtualization projects such as Storage Vmotion, Host Migrations, Storage Upgrades, Disk Alignments and Optimzation just to name a few.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default VirtualizationServices;
