import React from "react";
import MainSec from "../utils/MainSec.js";
import * as Icons from "@tabler/icons-react";

const WhyClockwork = () => {
  const items = [
    {
      title: "Expertise",
      description:
        "Our team of experienced and certified IT professionals can handle all aspects of your IT infrastructure, from network management to security to cloud computing.",
    },
    {
      title: "Proactiveness",
      description:
        "We take a proactive approach to IT management, monitoring your systems 24/7 to prevent problems from happening in the first place.",
    },
    {
      title: "Responsiveness",
      description:
        "When problems do occur, we respond quickly and efficiently to minimize downtime.",
    },
    {
      title: "Affordability",
      description:
        "We offer a variety of managed IT services packages to fit any budget.",
    },
    {
      title: "Scalability",
      description:
        "We can easily scale our services to meet the changing needs of your business.",
    },
    {
      title: "Clockwork Technologies' Cutting-Edge War Room Sessions",
      description:
        "The cutting-edge War Room Sessions offered by Clockwork Technologies.",
    },
  ];

  return (
    <>
      <MainSec
        src={require("../../images/why.jpg")}
        heading="why Clockwork?"
      />
      <section className="why_clockwork">
        <div className="container">
          <div className="why_clockwork_main">
            <div className="sub_section ">
              <h2 className="sub_heading">
                Clockwork Technologies: Your Partner in IT Success
              </h2>
              <p className="clockwork_text">
                At Clockwork Technologies, we believe that IT is a critical tool
                for business success. That's why we offer a wide range of
                managed IT services to help businesses of all sizes achieve
                their goals. We understand that every business is different, so
                we work closely with each customer to develop customized
                solutions that meet their unique needs and budget.
              </p>
            </div>
            <h2 className="sub_heading">
              Here are just a few of the benefits of working with Clockwork
              Technologies:
            </h2>
            <div className="why_clock_wrapper">
              <ul className="storage_main">
                {items.map((item, index) => (
                  <div className="sub_section " key={index}>
                    <h2 className="sub_heading">{item.title}</h2>
                    <p className="clockwork_text">{item.description}</p>
                  </div>
                ))}
              </ul>
              <p>
                Our cutting-edge war room sessions are a collaborative approach
                to developing IT solutions. We bring together our team of
                experts with your team to brainstorm ideas, identify challenges,
                and develop a plan of action. This ensures that we have a deep
                understanding of your business needs and that we develop a
                solution that meets all of your requirements. Contact us today
                to learn more about our services and how we can help you achieve
                your IT goals
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyClockwork;
