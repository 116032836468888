import React, { useRef, useEffect } from "react";
import MainSec from "../utils/MainSec.js";
import * as Icons from "@tabler/icons-react";
import { Element } from "react-scroll";

const About = () => {
  const serviceData = [
    {
      title: "Proactive Management",
      description: "Preventing issues before they affect you.",
    },
    {
      title: "Focus on Customer",
      description: "Satisfaction, not just technology.",
    },
    {
      title: "Highly Trained Staff",
      description: "Not just in technology, but also in customer services.",
    },

    {
      title: "Flexible Service Offering",
      description: "Tailored exactly to your business needs.",
    },
    {
      title: "Competitively Priced",
      description:
        "With a complete report demonstrating where and how we deliver value.",
    },
    {
      title: "Understanding of Business Model",
      description:
        "We monitor / constantly support your mission-critical services so there are no IT-related business interruptions.",
    },
  ];
  const mediumBusinesses = [
    {
      title: "Customer-Centricity",
      description:
        "We place our clients' objectives at the heart of our operations, ensuring every solution we provide resonates with their core goals.",
    },
    {
      title: "Collaboration",
      description:
        "We believe in the power of unity. By seamlessly integrating into our client's teams, we foster an environment of mutual growth and success.",
    },
    {
      title: "Innovation",
      description:
        "Our quest for excellence is driven by a thirst for innovation. We are constantly exploring novel ways to provide optimized solutions to our clients.",
    },
    {
      title: "Integrity",
      description:
        "Every step we take is grounded in honesty and transparency, ensuring that our clients always have full visibility and control over the projects we undertake together.",
    },
    {
      title: "Efficiency",
      description:
        "Our commitment to reducing costs and streamlining processes is unwavering. We utilize the best of technology and methodologies to ensure maximum productivity.",
    },
  ];

  const clockworkTechnologies = [
    {
      title: "Research-Driven Insights:",
      list: [
        "We stay ahead of the curve by tapping into global tech trends, market analyses, and customer feedback. Drawing from industry-leading sources and websites, our strategy is rooted in real-time, relevant data.",
      ],
    },
    {
      title: "Adaptive Framework:",
      list: [
        "Understanding that the IT landscape is perpetually evolving, our strategy framework is flexible and adaptive. This approach allows us to rapidly adjust to industry shifts, ensuring our services are always at the forefront.",
      ],
    },
    {
      title: "Customer-Centric Refinement:",
      list: [
        "Our strategy prioritizes the customer. Through regular feedback loops and client consultations, we fine-tune our offerings, ensuring they align with the changing needs of our clientele.",
      ],
    },
    {
      title: "Methodological Excellence:",
      list: [
        "We believe in the power of a structured, systematic approach. By implementing rigorous methodologies, we guarantee that every project we undertake is managed with precision, thoroughness, and an unmatched attention to detail.",
      ],
    },
    {
      title: "Internal Knowledge Sharing:",
      list: [
        "Clockwork Technologies thrives on collective intelligence. Our teams consistently share insights, learning from each project to enrich our collective knowledge base. This iterative learning process forms the bedrock of our strategy, fostering innovation from within.",
      ],
    },
    {
      title: "Benchmarking & Competitive Analysis:",
      list: [
        "We continually benchmark our services against industry leaders, identifying gaps and opportunities for advancement. This comparative perspective ensures that Clockwork Technologies remains a frontrunner in the IT integration and engineering space.",
        "By intertwining data-driven decision-making with our profound technical expertise, we've curated a strategy that not only propels Clockwork Technologies forward but also ensures our clients are always equipped with best-in-class solutions.",
      ],
    },
  ];

  return (
    <>
      <MainSec src={require("../../images/about.jpg")} heading="About us" />
      <section>
        <div className="container">
          <div className="exciting_offers_item">
            <div className="exciting_offers_content">
              <h2 className="exciting_offers_heading">
                About Clockwork Technologies
              </h2>
              <div className="exciting_offers_flex">
                <p>
                  Founded in 2009, Clockwork Technologies stands as a testament
                  to innovation and dedication in the ever-evolving landscape of
                  technological solutions. Over more than a decade, we have
                  steadily forged a distinct identity, assisting our valued
                  clients in addressing their most challenging issues. Our ethos
                  is grounded in translating intricate problems into
                  streamlined, cost-effective solutions. This approach has
                  anchored our strong presence across varied sectors, from
                  government agencies to the healthcare realm, and the private
                  sector. A rich history spanning over a decade showcases the
                  deep trust our clients have in us and the consistent results
                  that Clockwork Technologies has been known to deliver. Our
                  commitment is not just to meet expectations, but to surpass
                  them. Dive into the world of Clockwork Technologies, where we
                  turn challenges into achievable solutions. Mission, Vision,
                  and Values of Clockwork Technologies
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Element className="about_section bg" id="mission">
        <div className="container">
          <div className="exciting_offers_item">
            <div className="exciting_offers_content">
              <h2 className="exciting_offers_heading">Mission</h2>
              <div className="exciting_offers_flex column">
                <p>
                  At Clockwork Technologies, our primary mission is to be a
                  catalyst in our customer's journey towards achieving their
                  primary objectives. We strive to amplify their capabilities by
                  enhancing service quality, streamlining processes, and
                  significantly reducing costs. Our commitment is such that we
                  don’t just serve our customers – we integrate ourselves into
                  their teams. This close-knit collaboration ensures that we can
                  accelerate their path to goal realization, delivering results
                  that are not only efficient in terms of cost but also remain
                  under their complete control.
                </p>
              </div>
            </div>
            <div className="image">
              <figure>
                <img
                  src="https://img.freepik.com/free-photo/global-businessman-glowing-with-success-standing-tall-generated-by-ai_188544-27039.jpg?t=st=1693662296~exp=1693665896~hmac=f22e692855b72da5a6a53945e99e04e06a9824b8e1cea5f9ea9b061140f068dc&w=1060"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </Element>
      <Element className="about_section" id="strategy">
        <div className="container">
          <div className="exciting_offers_content">
            <h2 className="exciting_offers_heading">Strategy</h2>
            <div className="exciting_offers_flex">
              {clockworkTechnologies.map((service, index) => (
                <ul className="list career_card" key={index}>
                  <h2 className="sub_heading">{service.title}</h2>
                  {service.list.map((elem, subIndex) => (
                    <li key={subIndex}>
                      <Icons.IconPoint /> <span>{elem}</span>
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
        </div>
      </Element>
      <Element className="about_section bg" id="vision">
        <div className="container">
          <div className="exciting_offers_item">
            <div className="exciting_offers_content">
              <h2 className="exciting_offers_heading">Vision</h2>
              <div className="exciting_offers_flex column">
                <p>
                  To be the trusted partner for organizations worldwide,
                  assisting them in harnessing the power of technology to
                  simplify the complex and catalyze innovation, driving them
                  forward into a future of unlimited potential.
                </p>
              </div>
            </div>

            <div className="image">
              <figure>
                <img
                  src="https://img.freepik.com/free-photo/wireless-businessman-holding-futuristic-internet-equipment-network-generated-by-ai_188544-26813.jpg?t=st=1693663179~exp=1693666779~hmac=b3a700c7a6dfca0d44f80f2e6e7cd9ada669a9102e0409a9c794456a9bf37a56&w=1060"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </Element>
      <Element className="about_section" id="values">
        <div className="container">
          <div className="exciting_offers_content">
            <h2 className="exciting_offers_heading">Values</h2>
            <div className="exciting_offers_flex column">
              <ul className="list">
                {mediumBusinesses.map((service, index) => (
                  <li key={index}>
                    <Icons.IconPoint />
                    <span>
                      <b>{service.title} : </b>
                      {service.description}
                    </span>
                  </li>
                ))}
              </ul>
              <p>
                The benefits of hosted email security solutions are quite clear
                and for many businesses a hosted service will be a much more
                cost effective option than on-premises solutions.
              </p>
            </div>
          </div>
        </div>
      </Element>
    </>
  );
};

export default About;
