import React from 'react';

const Map = ({ address }) => {
  const mapUrl = `https://maps.google.com/maps?q=${encodeURIComponent(
    address
  )}&t=&z=15&ie=UTF8&iwloc=&output=embed`;
  return (
    <div className="mapouter">
      <div className="gmap_canvas">
        <iframe
          width="100%"
          height="510"
          id="gmap_canvas"
          src={mapUrl}
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          title="Google Map"
        ></iframe>
      </div>
    </div>
  );
};

export default Map;
