import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Icons from "@tabler/icons-react";
import { Button, Input } from "../utils/Components.js";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { HeroSlider, ProactiveServices,imageArray } from "../../api/api.js";
import ProactiveServicesImg from "../../images/services-1.png";
import ProactiveServicesImg2 from "../../images/common/services_2.svg";
import company1 from "../../images/companies/companies_1.png";
const Home = () => {
  // Swiper hero secition configuration object
  SwiperCore.use([Navigation, Autoplay]);
  const swiperConfig = {
    spaceBetween: 30,
    slidesPerView: 1,
    loop: true,
    speed: 800,
    navigation: {
      prevEl: ".swiper-button-prev",
      nextEl: ".swiper-button-next",
    },
    // autoplay: { delay: 3000 }
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    phone: "",
  });

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <>
      {/* Hero section*/}
      <section className="hero">
        <div className="container">
          <div className="hero_main">
            <Swiper {...swiperConfig}>
              {HeroSlider.map(function (slide, key) {
                return (
                  <SwiperSlide key={key}>
                    <div className="hero_slide">
                      <figure className="hero_slide_img">
                        <img src={slide.image} alt="" />
                      </figure>
                      <div className="hero_slide_contnet">
                        <h2 className="hero_title">{slide.title}</h2>
                        <p className="hero_description">{slide.description}</p>
                        <Link to={slide.link} className="btn">
                          Read more
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
              <div className="arrows swiper-button-prev">
                <Icons.IconArrowNarrowLeft />
              </div>
              <div className="arrows swiper-button-next">
                <Icons.IconArrowNarrowRight />
              </div>
            </Swiper>
          </div>
        </div>
      </section>
      {/* services 1 */}
      <section className="proactive_services bg">
        <div className="container">
          <div className="proactive_services_main">
            <div className="p_s_content">
              <h2 className="p_s_heading">proactive it managed services</h2>
              <p className="p_s_description">
                clockwork technologies managed services provide you with
              </p>
              <ul className="p_s_list">
                {ProactiveServices.map((item, index) => (
                  <li key={index}>
                    <Icons.IconDiscountCheckFilled />
                    {item}
                  </li>
                ))}
              </ul>
              <Link to="/services/managed-services" className="btn">
                Read more
                <Icons.IconChevronRight size={18} />
              </Link>
            </div>
            <figure className="p_s_img">
              <img src={ProactiveServicesImg} alt="" />
            </figure>
          </div>
        </div>
      </section>
      {/* services 2 */}
      <section className="professional_services">
        <div className="container">
          <div className="professional_services_main">
            <div className="p_s_headings">
              <h2 className="p_s_heading">professional services</h2>
              <p className="p_s_description">
                enhance your company's it capabilities with clockwork
                technologies' professional services.
              </p>
            </div>
            <div className="p_s_main_parent">
              <figure>
                <img src={ProactiveServicesImg2} alt="" />
              </figure>
              <div className="p_s_main_2">
                <div className="p_s_card">
                  <Icons.IconCloudComputing />
                  <span>virtualization (internal and external clouds)</span>
                  <Link to="/services/virtualization-services" className="btn p_s_card_btn">Read More</Link>
                </div>
                <div className="p_s_card">
                  <Icons.IconRecycle />
                  <span>
                    Application Support & Business Process Improvement
                  </span>
                  <Link to="/services/application-support" className="btn p_s_card_btn">Read More</Link>
                </div>
                <div className="p_s_card">
                  <Icons.IconAffiliate />
                  <span>Networking & Security</span>
                  <Link to="/services/network-and-security" className="btn p_s_card_btn">Read More</Link>
                </div>
                <div className="p_s_card">
                  <Icons.IconDatabase />
                  <span>Storage</span>
                  <Link to="/services/storage" className="btn p_s_card_btn">Read More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* get qoute 3 */}
      <section className="get_qoute bg">
        <div className="container">
          <div className="p_s_headings">
            <h2 className="p_s_heading">get a free quote</h2>
            <p className="p_s_description">
              Get a free quote now to enhance your business...
            </p>
          </div>
          <div className="get_qoute_main">
            <form>
              <div className="form_wrapper">
                <Input
                  value={formData.name}
                  onChange={(value) => handleInputChange("name", value)}
                  label="Name"
                  type="text"
                />
                <Input
                  value={formData.email}
                  onChange={(value) => handleInputChange("email", value)}
                  label="Email"
                  type="email"
                />
                <Input
                  value={formData.company}
                  onChange={(value) => handleInputChange("company", value)}
                  label="Company"
                  type="text"
                />
                <Input
                  value={formData.phone}
                  onChange={(value) => handleInputChange("phone", value)}
                  label="Phone"
                  type="tel"
                />
              </div>
              <Button className="qoute_btn">Submit</Button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
