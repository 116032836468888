import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header.js';
import Main from './Main.js';
import Footer from './Footer.js';
import { imageArray } from '../../api/api.js';

const Layout = () => {
  const location = useLocation();
  const isTechnologyPage = location.pathname.includes('technology');
  const isClientLoginPage = location.pathname.includes('client-login');
  const companiesClass = isTechnologyPage || isClientLoginPage ? 'companies bg d-none' : 'companies bg';

  return (
    <>
      <Header />
      <Main />

      {/* companies */}
      <section className={companiesClass}>
        <div className="container">
          <div className="companies_main">
            {imageArray.map((image) => (
              <figure key={image.id}>
                <img src={image.src} alt={image.alt} />
              </figure>
            ))}
          </div>
        </div>
      </section>
      
      <Footer />
    </>
  );
};

export default Layout;
