import React, { useRef, useEffect } from 'react';
import MainSec from "../utils/MainSec.js";
import * as Icons from "@tabler/icons-react";
const ExcitingOffers = () => {
	// Create a reference for the target elements
  const fullSupportRef = useRef(null);
  const virtualizationRef = useRef(null);
  const mediumBusinessRef = useRef(null);

  // Function to handle smooth scrolling to the target element
  const handleSmoothScroll = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Scroll to the target element when the component mounts
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#fullSupportService") {
      handleSmoothScroll(fullSupportRef);
    } else if (hash === "#virtualizationServices") {
      handleSmoothScroll(virtualizationRef);
    } else if (hash === "#mediumBusinesses") {
      handleSmoothScroll(mediumBusinessRef);
    }
  }, []);
  const serviceData = [
    {
      title: "Proactive Management",
      description: "Preventing issues before they affect you.",
    },
    {
      title: "Focus on Customer",
      description: "Satisfaction, not just technology.",
    },
    {
      title: "Highly Trained Staff",
      description: "Not just in technology, but also in customer services.",
    },
    {
      title: "Flexible Service Offering",
      description: "Tailored exactly to your business needs.",
    },
    {
      title: "Competitively Priced",
      description:
        "With a complete report demonstrating where and how we deliver value.",
    },
    {
      title: "Understanding of Business Model",
      description:
        "We monitor / constantly support your mission-critical services so there are no IT-related business interruptions.",
    },
  ];
  const benefitsData = [
    "Reduction in hardware and operating costs by as much as 50% with server consolidation.",
    "Server consolidation and containment with virtualization reduces data center complexity by reducing the number of servers that IT organizations need to manage.",
    "Dramatically reduce the time to provision a new server.",
    "Streamline and eliminate common administrative tasks such as enabling IT to manage a growing server environment with existing resources.",
    "By fully utilizing existing server resources, Clockwork Technologies improves the ability to handle future growth.",
    "Virtual Infrastructure aggregates x86 server resources into pools that can reliably support CPU utilization exceeding 80% with continuous load balancing.",
    "Clockwork Technologies can assist you in design, configuration, deployment, and management of your private cloud configuration.",
  ];
const mediumBusinesses = [
    {
      title: "License Costs",
      description: "Because the customer is not running their own server, they also save on software licensing costs. Furthermore, they are simply paying a per-user license cost to the hosted provider.",
    },
    {
      title: "Equipment Costs",
      description: "By choosing a hosted service, the customer is not required to purchase their own server hardware to run the security product on their premises.",
    },
    {
      title: "Support Costs",
      description: "Support is included in the monthly fee to the hosted provider, so the customer is not required to hire and retain staff to manage an on-premise solution. The hosted provider is responsible for all maintenance and upgrades to keep the service running smoothly.",
    },
    {
      title: "Bandwidth",
      description: "Because any virus or spam emails are filtered by the hosted provider, that traffic never reaches the customer's network, saving their bandwidth which is both a cost and a performance benefit.",
    },
    {
      title: "Scalability",
      description: "The customer benefits by only having to pay per-user, and then having the flexibility to scale up as necessary by buying more licenses. For on-premises solutions, this may eventually lead to outgrowing an existing server, whereas with hosted services, the provider manages their overall capacity needs for all of their customers and is responsible for scaling up as necessary to meet demand.",
    },
    {
      title: "Features",
      description: "End user control and comprehensive reporting are two features common to hosted services. Some on-premises solutions lack these important features.",
    },
    {
      title: "Simplicity",
      description: "For large businesses with multiple network entry points, a hosted service offers a single point of entry for email rather than having to manage multiple points of entry, each with their own security product installed.",
    },
    {
      title: "Flexibility",
      description: "If a hosted service is not performing well or meeting expectations, the customer can simply switch to another service without wasting expenditure. For on-premises solutions, switching to a new product can be costly because the existing product has already been paid for.",
    },
    {
      title: "Compatibility",
      description: "Hosted services operate independently of their customer's normal choice of server operating system or email platform. For on-premises solutions, a customer is often constrained by which products will be compatible with their other systems.",
    },
  ];
  return (
    <>
      <MainSec
        src={require("../../images/career.jpg")}
        heading="exciting offers"
      />
      <section className="exciting_offers" id="fullSupportService" ref={fullSupportRef}>
        <div className="container">
            <div className="exciting_offers_item">
              <div className="exciting_offers_content">
                <h2 className="exciting_offers_heading">
                  Full Support Service
                </h2>
                <div className="exciting_offers_flex">
                	
                <p>
                  Distinct from our normal support service, our ‘full support'
                  service is designed for customers who don't have an expert
                  in-house IT support function and who prefer to focus
                  management energies elsewhere. With ‘full support', we take
                  complete responsibility for our customer's overall IT
                  requirements, managing day-to-day IT services, providing
                  long-term advice regarding IT-related business issues, and
                  delivering exceptional value.
                </p>
                <ul className="list">
                  {serviceData.map((service, index) => (
                    <li key={index}>
                      <Icons.IconPoint />
                      <span>
                        <b>{service.title} : </b>
                        {service.description}
                      </span>
                    </li>
                  ))}
                </ul>
                <p>
                  Clockwork Technologies is passionate about enabling customers
                  to focus on their strengths; confident in the knowledge that
                  their IT systems will reliably support them and their
                  colleagues in their business pursuits. This has been our focus
                  over the years and we believe we've become quite good at it.
                  If you are serious about getting value from your current IT
                  systems, or you're looking for ways to keep your business
                  ahead of the competition, you will be amazed at how Clockwork
                  Technologies can help.
                </p>
                </div>
              </div>
            </div>
        </div>
      </section>
      <section className="exciting_offers bg" id="virtualizationServices" ref={virtualizationRef}>
        <div className="container">
            <div className="exciting_offers_item">
              <div className="exciting_offers_content">
                <h2 className="exciting_offers_heading">
                  Virtualization Services
                </h2>
                <div className="exciting_offers_flex column">
                	
                <p>
                  All of our trained and certified engineers are dedicated to provide unique virtualization services based on your environment. By assessing your environment and working with a wide variety of vendors we provide you with the best solution for your business needs. Below are some of the benefits of making Clockwork Technologies responsible for your virtual environment
                </p>
                <ul className="list">
                  {benefitsData.map((service, index) => (
                    <li key={index}>
                      <Icons.IconPoint />
                      <span>
                        {service}
                      </span>
                    </li>
                  ))}
                </ul>
                </div>
              </div>
            </div>
        </div>
      </section>
      <section className="exciting_offers" id="mediumBusinesses" ref={mediumBusinessRef}>
        <div className="container">
            <div className="exciting_offers_item">
              <div className="exciting_offers_content">
                <h2 className="exciting_offers_heading">
                  Hosted Spam Service for Small and Medium Businesses
                </h2>
                <div className="exciting_offers_flex column">
                <p>
                  Spam is a waste of time and resources. Would it be ideal to see and believe that all the emails that are coming to your inbox are genuine and sanitized? Let Clockwork Technologies help you setup the industry leading hosted Spam service.
                </p>
                	<h4 className="sub_heading">Here are some of the benefits of using hosted spam service:</h4>
                <ul className="list">
                  {mediumBusinesses.map((service, index) => (
                    <li key={index}>
                      <Icons.IconPoint />
                      <span>
                        <b>{service.title} : </b>
                        {service.description}
                      </span>
                    </li>
                  ))}
                </ul>
                <p>The benefits of hosted email security solutions are quite clear and for many businesses a hosted service will be a much more cost effective option than on-premises solutions.</p>
                </div>
              </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default ExcitingOffers;
