import React from 'react';

export const Button = ({ className, ...props }) => {
  return <button className={`btn ${className}`} {...props} />;
};

export const Input = ({ type,value, onChange, label, className }) => {
  return (
    <div className={`input_field ${className}`}>
      <label htmlFor={label}>{label}</label>
      <input
        type={type}
        id={label}
        placeholder={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};