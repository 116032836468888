import React from "react";
import MainSec from "../utils/MainSec.js";
import * as Icons from "@tabler/icons-react";
import {careersData} from '../../api/api.js'

const Career = () => {
  

  return (
    <>
      <MainSec src={require("../../images/career.jpg")} heading="Career" 
        className="career_img"
      
      />
      <section className="career">
        <div className="container">
          <div className="section_heading">
           <p className="section_pera">We almost always have openings for the positions below. Please feel free to send your resume to info@clockworktechnologies.com and we will contact you as soon as we see a fit.</p>
          </div>
          <div className="career_main">
            {careersData.map((career, index) => (
              <div key={index} className="career_card">
                <div className="career_contnet">
                  <h2>{career.title}</h2>
                  <p>{career.desc}</p>
                  <h2 className="sub_heading">Key Responsibilities:</h2>
                  <ul className="list">
                    {career.responsibilities.map(function (responsibilitie, index) {
                      return (
                        <li key={index}>
                          <Icons.IconPoint />
                          <span>{responsibilitie}</span>
                        </li>
                      );
                    })}
                  </ul>
                  <h2 className="sub_heading">Requirements</h2>
                  <ul className="list">
                    {career.requirements.map(function (requirement, index) {
                      return (
                        <li key={index}>
                          <Icons.IconPoint />
                          <span>{requirement}</span>
                        </li>
                      );
                    })}
                  </ul>
                  { career.desc2 && <p>{career.desc}</p>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Career;
