import React from "react";
import MainSec from "../utils/MainSec.js";
import * as Icons from "@tabler/icons-react";

const ManagedServices = () => {
  const ITBetifits = [
    "Maximize your IT Budget",
    "Greater Technical Expertise",
    "Standardized Environment",
    "Ease of Mind",
    "One phone number for all your IT needs",
  ];
  const services = [
    "Patch Management",
    "Antivirus and Spyware (End Point Security)",
    "Backup and Restore (On-site)",
    "Backup and Restore (Online, Off-Site)",
    "Inventory and Asset Management",
    "Mission Critical Services Monitoring",
    "Major Applications Monitoring",
    "Performance Monitoring (Hard Drive, Memory, Event Logs and Paging)",
    "Firewall Management",
    "Basic Server Administration (User Accounts, File Sharing, Major Application Support)",
    "VPN Management",
    "Printers and Storage (NAS) Management",
    "Network Monitoring and Troubleshooting",
    "Help Desk",
    "Remote Support",
    "On-Site Support",
    "After Hours Support",
    "Vendor Management",
    "Technology Planning and Enhancements",
  ];
  return (
    <>
      <MainSec
        src={require("../../images/manage-services.jpg")}
        heading="manage services"
      />
      <section className="manage_services">
        <div className="container">
          <div className="manage_services_main">
            <div className="manage_service_item">
              <p className="clockwork_text">
                Clockwork Technologies' state of the art Managed Services
                provide you with ease of mind and predictable IT expenses. We
                become responsible for managing your servers, desktops, network,
                security or the entire IT infrastructure. Our Managed Services
                Engine is integrated with the leading MSP tools. Our main goal
                is to make sure your IT infrastructure is working properly and
                efficiently to support your business.
                <br />
                All of our services are completely customizable and we're
                flexible enough to build a customized Managed Servicing package
                for you.
              </p>
            </div>
            <div className="manage_service_item">
              <h4 className="sub_heading">
                Here are a few of the benefits of Managed Services:
              </h4>
              <ul className="list">
                {ITBetifits.map((item, index) => (
                  <li key={index}>
                    <Icons.IconPoint />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <h4 className="sub_heading">
            Our Service Plans include but are not limited to:
          </h4>
          <ul className="mng_list">
            {services.map((service, index) => (
              <li key={index}>
                <span>{index + 1}</span>
                <p>{service}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};

export default ManagedServices;
