import React from 'react'
import Layout from './components/layout/Layout.js'
import "./styles/style.css"
import ScrollToTop from './components/layout/ScrollToTop.js'
const App = () => {
  return (
    <>

       <ScrollToTop />
      <Layout/>
    </>
  )
}

export default App