import Home from '../pages/Home.js';
import Storage from '../pages/Storage.js';
import Services from '../pages/Services.js';
import WhyClockwork from '../pages/WhyClockwork.js';
import ManagedServices from '../pages/ManagedServices.js';
import ApplicationSupport from '../pages/ApplicationSupport.js';
import DataCenterGrooming from '../pages/DataCenterGrooming.js';
import NetworkAndSecurity from '../pages/NetworkAndSecurity.js';
import VirtualizationServices from '../pages/VirtualizationServices.js';
import ClientLogin from '../pages/ClientLogin.js';
import Technology from '../pages/Technology.js';
import ExcitingOffers from '../pages/ExcitingOffers.js'
import Contact from '../pages/Contact.js';
import Career from '../pages/Career.js';
import About from '../pages/About.js';
import Security from '../pages/Security.js';

const customRoutes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/our-expertise/storage',
    element: <Storage />,
  },
  {
    path: '/our-expertise',
    element: <Services />,
  },
  {
    path: '/why-clockwork',
    element: <WhyClockwork />,
  },
  {
    path: '/managed-services',
    element: <ManagedServices />,
  },
  {
    path: '/our-expertise/application-support',
    element: <ApplicationSupport />,
  },
  {
    path: '/our-expertise/data-center-grooming',
    element: <DataCenterGrooming />,
  },
  {
    path: '/our-expertise/network-and-security',
    element: <NetworkAndSecurity />,
  },
  {
    path: '/our-expertise/systems-engineering',
    element: <VirtualizationServices />,
  },
  {
    path: '/client-login',
    element: <ClientLogin />,
  },
  {
    path: '/technology',
    element: <Technology />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '/exciting-offers',
    element: <ExcitingOffers />,
  },
  {
    path: '/career',
    element: <Career />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/security',
    element: <Security />,
  },
];

export default customRoutes;
