import React from 'react'
import {Routes,Route} from 'react-router-dom'
import CustomRoutes from './Routes.js'
const Main = () => {
	return (
		<>
			<Routes>
				{
					CustomRoutes.map((route, key) => (
						<Route key={key} exect path={route.path} element={route.element}/>
					))
				}
			</Routes>
		</>
	)
}

export default Main