import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const menuItems = [
    { text: 'Why Clockwork?', link: '/why-clockwork' },
    { text: 'Technology', link: '/technology' },
    { text: 'Services', link: '/services' },
    { text: 'Career', link: '/career' },
    { text: 'Client Login', link: '/client-login' },
    { text: 'Contact Us', link: '/contact' }
  ];

  return (
    <footer className="footer">
      <nav>
        <ul>
          {menuItems.map((item, index) => (
            <li key={index}>
              <Link to={item.link}>{item.text}</Link>
            </li>
          ))}
        </ul>
      </nav>
      <p className="footer-text">
        Clockwork Technologies: IT Services, Professional Services, Small Business IT Support
      </p>
    </footer>
  );
};

export default Footer;
