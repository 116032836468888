import React from 'react';
import MainSec from '../utils/MainSec.js';
import * as Icons from "@tabler/icons-react";
import { Element } from "react-scroll";

const Services = () => {
  const clockworkTechnologies = [
    {
      title: "Systems Engineering",
      list: [
        "Expertise in Active Directory, VMware, comprehensive storage integration and management, and Office 365.",
        "Design, implementation, and maintenance of intricate IT systems, tailored to client-specific needs.",
        "Proficiency in both traditional architectures and cutting-edge solutions.",
      ],
    },
    {
      title: "Cloud Services",
      list: [
        "Mastery in migration and management across all major cloud platforms: AWS, Azure, and Google Cloud.",
        "Cloud strategy, deployment, and optimization for businesses of all scales.",
      ],
    },
    {
      title: "Programming & Development",
      list: [
        "Specialized in web application programming and custom solutions for evolving and migrating legacy systems.",
        "Expertise in programming, designing, and optimizing Salesforce environments.",
        "Use of modern programming languages, frameworks, and agile development methodologies for swift project delivery.",
      ],
    },
    {
      title: "Network Engineering",
      list: [
        "Design and deployment of intricate LAN, WAN, and Wifi infrastructures, capable of supporting thousands of locations, including multiple disaster recovery sites using the latest technologies.",
        "Certified engineers adapt at supporting diverse networking environments from LAN/WAN to Wifi.",
        "A unique approach that involves comprehensive environmental analysis, enabling us to offer multiple, cost-effective solutions tailored to each customer's needs.",
      ],
    },
    {
      title: "Administration",
      list: [
        "Comprehensive administrative capabilities, covering VMware, servers, change management, and program management.",
        "A team of certified engineers with vast experiential knowledge and recognized qualifications in these domains.",
      ],
    },
    {
      title: "Assessment and Research",
      list: [
        "Services to enhance project performances, identify the most suitable modernization paths, and optimize procurement processes.",
        "Comprehensive evaluations for system and network enhancements, along with white paper generation for significant projects, all aimed at saving customer time and expenses.",
      ],
    },
    {
      title: "Consulting",
      list: [
        "Strategic consultations ensuring the best fit for project scopes, significantly reducing time inefficiencies.",
        "A commitment to delivering superior service quality while minimizing associated costs for our clients.",
      ],
    },
    {
      title: "Company Strategy",
      list: [
        "We stay ahead of the curve by tapping into global tech trends, market analyses, and customer feedback.",
        "Understanding that the IT landscape is perpetually evolving, our strategy framework is flexible and adaptive.",
        "Our strategy prioritizes the customer through regular feedback loops and client consultations.",
        "We believe in the power of a structured, systematic approach. By implementing rigorous methodologies, we guarantee that every project we undertake is managed with precision, thoroughness, and an unmatched attention to detail.",
        "Clockwork Technologies thrives on collective intelligence. Our teams consistently share insights, learning from each project to enrich our collective knowledge base. This iterative learning process forms the bedrock of our strategy, fostering innovation from within.",
        "We continually benchmark our services against industry leaders, identifying gaps and opportunities for advancement. This comparative perspective ensures that Clockwork Technologies remains a frontrunner in the IT integration and engineering space.",
      ],
    },
  ];

  

  return (
    <>
      <MainSec src={require("../../images/expertise.jpg")} heading="Our Expertise" />

      <section id="expertise">
        <div className="container">
          <div className="exciting_offers_content">
            <div className="exciting_offers_flex">
              {clockworkTechnologies.map((service, index) => (
                <Element key={index} id={service.title.toLowerCase().replace(/\ /g, "-")}>
                  <ul className="list career_card">
                    <h2 className="sub_heading">{service.title}</h2>
                    {service.list.map((elem, subIndex) => (
                      <li key={subIndex}>
                        <Icons.IconPoint /> <span>{elem}</span>
                      </li>
                    ))}
                  </ul>
                </Element>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;