import React from "react";
import MainSec from "../utils/MainSec.js";
import * as Icons from "@tabler/icons-react";

const NetworkAndSecurity = () => {
  const securitySolutions = [
    "Routing infrastructure",
    "Switching infrastructure",
    "Wireless infrastructure",
    "VPN & Firewall infrastructure",
    "Remote Access VPN infrastructure",
  ];
  const securityServices = [
    "Risk Assessment",
    "Operating System Hardening",
    "Host-based Security infrastructure",
    "Network Readiness / Capability Assessment",
    "Extended Network Infrastructure Solutions",
    "Advanced Security Infrastructure Solutions",
    "Vulnerability Management & Remediation",
    "Intrusion Detection System Management and Rules tweaking",
    "Anti-Virus / Spyware / Spam / Phishing",
    "Single Sign-on",
    "Security Awareness Training",
  ];
  return (
    <div>
      <MainSec
        src={require("../../images/career.jpg")}
        heading="Networking & Security"
      />
      <section className="network_services">
        <div className="container">
          <div className="network_services_main">
            <p className="clockwork_text">
              Clockwork Technologies can help you Design, Install and Maintain
              your Network Infrastructure. With a wide variety of expertise we
              support all the major vendors from Cisco and Juniper to Netgear
              and Dell.
              <br/>
              <br/>
              Networking is at the core of any IT enterprise.
              Achieving and maintaining optimal network health and connectivity
              is crucial to productivity and critical to business continuity.
              Securing and protecting your environment is paramount for
              succesful business survivability alongside legislative initiatives
              which drive corporate compliancy requirements.
            </p>
            <div className="network_service_item">
              <h4 className="sub_heading">Networking & Security Services</h4>
              <ul className="list">
                {securityServices.map((item, index) => (
                  <li key={index}>
                    <Icons.IconPoint />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div className="network_service_item">
              <h4 className="sub_heading">Networking & Security Solutions</h4>
              <ul className="list">
                {securitySolutions.map((item, index) => (
                  <li key={index}>
                    <Icons.IconPoint />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NetworkAndSecurity;
