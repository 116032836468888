import React from 'react';
import { Link } from 'react-router-dom';
import * as Icons from "@tabler/icons-react";

const ClientLogin = () => {
	return (
		<>
			<section className="client_login">
				<div className="container">
					<div className="client_login_main">
						<h2>Coming Soon</h2>
						<p>This page is under development.</p>
						<Link to="/" className="btn">
							<Icons.IconArrowNarrowLeft />
							<span>Go Back to Home</span>
						</Link>
					</div>
				</div>
			</section>
		</>
	);
};

export default ClientLogin;
